<script>
// Extensiones
import View from '@/components/View'

// Mixins
import LoadSections from '@/mixins/load-sections'

export default {
  name: 'Equipment',

  metaInfo: { title: 'Equipment'},

  extends: View,

  mixins: [
    LoadSections([
      'equipment-header',
      'equipment-grid',
      'equipment-bottom',
    ]),
  ],

  props: {
    id: {
      type: String,
      default: 'equipment',
    },
  },
}
</script>